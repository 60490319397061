import client from '@/client'
import userTimeRecording from '@/mixins/userTimeRecording'

export default {
  mixins: [ userTimeRecording ],
  computed: {
    operatingUser () {
      return this.$store.state.operatingUser
    },
    userActiveOrderId() {
      if (this.$store.state.userTimeRecordingStatus) {
        return this.$store.state.userTimeRecordingStatus.active_order
      }
      return null
    },
  },
  methods: {
    async trackTime(orderId, kind) {
      await this.getUserTimeRecordingStatus(this.operatingUser.id)

      // new
      const data_v2 = {
        user_id: this.operatingUser.id,
        order_id: orderId
      }

      try {
        const token = localStorage['auth._token.jwt']

        // track v2
        if (kind === 'start' || kind === 'stop') {
          const res_v2 = await client.put(`/employee_time_recordings/toggle_working_time`, data_v2, { headers: { "Authorization": `Bearer ${token}`, 'Request-Source': 'terminal' }})
        
          if (res_v2) {
            console.log(`Flag ${kind} tracked v2`)
          }
        } else if (kind === 'pause_start' || kind === 'pause_stop') {
          const res_v2 = await client.put(`/employee_time_recordings/toggle_pause`, data_v2, { headers: { "Authorization": `Bearer ${token}`, 'Request-Source': 'terminal' }})
        
          if (res_v2) {
            console.log(`Flag ${kind} tracked v2`)
          }
        } else if (kind === 'work') {

          // if the current order ID is the same as the active child order ID, we dont need another api call as it would end the project time
          if (this.userActiveOrderId === orderId) {
            console.log('Same order ID, no need to toggle project time')
            return
          }
          const res_v2 = await client.put(`/employee_time_recordings/toggle_project_time`, data_v2, { headers: { "Authorization": `Bearer ${token}`, 'Request-Source': 'terminal' }})
        
          if (res_v2) {
            console.log(`Flag ${kind} tracked v2`)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}