<template>
  <div class="scan-card" align="center">
    <img src="@/assets/images/logo.png" @click="checkRefresh" />
    <p class="welcome" v-if="!lockedScreen">
      Willkommen!
    </p>
    <p>Anmeldung<span v-if="modus === 'pause'"> | Kommen & Gehen</span></p>
    <div class="field">
      <input v-focus class="input scan" v-debounce:500ms="checkUser" v-model="scannedUserId" placeholder="Mitarbeiter Karte scannen" />
    </div>
    <p class="has-text-danger" v-if="error">{{ error }}</p>
  </div>
</template>

<script>

// const experimentalUserId = "1455FF22"

import Vue from 'vue'
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)
import client from '@/client'

export default {
  props: {
    lockedScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: null,
      scannedUserId: null,
      refreshCount: 0
    }
  },
  computed: {
    operatingUser: {
      get () {
        return this.$store.state.operatingUser
      },
      set (val) {
        this.$store.commit('setOperatingUser', val)
      }
    },
  },
  methods: {
    async checkUser () {
      this.error = null
      try {
        const token = localStorage['auth._token.jwt']
        const res = await client.get(`/users?internal_identifier=${this.scannedUserId}`, { headers: { "Authorization": `Bearer ${token}` }})
        // result is a collection so it has to be filtered again
        if (res) {
          const foundUser = res.data.resources.find(user => user.internal_identifier === this.scannedUserId)
          if (foundUser) {
            this.operatingUser = foundUser
            this.error = null

            this.$emit('setStep')
          } else {
            this.setError()
          }
        } else {
          this.setError()
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          this.$store.commit('setAuthorized', false)
        }
        this.setError()
      }
    },
    setError () {
      if (this.scannedUserId) {
        this.error = `Benutzer ${this.scannedUserId} nicht erkannt!`
      }
      this.scannedUserId = ''
    },
    checkRefresh () {
      this.refreshCount += 1
      if (this.refreshCount >= 5) {
        this.refreshCount = 0
        
        window.location.reload()
        alert("Aktualisierung des Terminals erfolgreich!")
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.welcome
  font-size: 40px
  padding: 20px 0 20px 0
</style>
