import Vue from 'vue'
import App from './App.vue'
import store from "@/store"

Vue.config.productionTip = false

import '@/assets/sass/main.sass'

// mixins
import globals from '@/mixins/globals'
import trackTime from '@/mixins/trackTime'
Vue.mixin(globals)
Vue.mixin(trackTime)

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faLock)
library.add(faCheck)
library.add(faXmark)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
