<template>
  <div>
    {{ dayOfWeek }} {{ date }} {{ clock }}
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      clockInitialized: false,
      dayOfWeek: moment().locale('de').format('dddd'),
      date: moment().locale('de').format('D. MMMM')
    }
  },
  computed: {
    clock: {
      get () {
        return this.$store.state.clock
      },
      set (val) {
        this.$store.commit('setClock', val)
      }
    }
  },
  mounted () {
    this.setTime()
  },
  methods: {
    setTime () {
      setInterval(() => {
        this.clock = moment().locale('de').format('H:mm')
        this.dayOfWeek = moment().locale('de').format('dddd')
        this.date = moment().locale('de').format('D. MMMM')
      }, 1000)
    },
  },
}
</script>
<style lang="sass">
</style>
