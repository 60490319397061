<template>
  <div>
    <div class="button is-small pause" @click="summaryModalOpen = true">Zusammenfassung</div>

    <SummaryModal
      v-if="summaryModalOpen"
      :has-ctas="false"
      @close="summaryModalOpen = false"
    >
      <CurrentChainSummary />
    </SummaryModal>
  </div>
</template>

<script>
import SummaryModal from '@/components/Modal'
import CurrentChainSummary from '@/components/CurrentChainSummary'
export default {
  components: { SummaryModal, CurrentChainSummary },
  props: {
    currentTask: Object
  },
  data () {
    return {
      summaryModalOpen: false
    }
  },
  computed: {
    taskId () {
      let taskId = null
      if (this.currentTask) {
        taskId = this.currentTask.id
      }
      return taskId
    }
  },
  methods: {
    close () {
      this.warningModalOpen = false
    }
  }
}
</script>

<style lang="sass" scoped>
.button.pause
  height: 35px
</style>
