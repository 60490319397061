import moment from 'moment'
export default {
  directives: { focus: {
    inserted: function (el) {
        el.focus()
      }
    }
  },
  computed: {
    currentStep: {
      get () {
        return this.$store.state.currentStep
      },
      set (val) {
        this.$store.commit('setCurrentSet', val)
      }
    },
    timerRunning: {
      get () {
        return this.$store.state.timerRunning
      },
      set (val) {
        this.$store.commit('setTimerRunning', val)
      }
    },
    locked: {
      get () {
        return this.$store.state.locked
      },
      set (val) {
        this.$store.commit('setLocked', val)
      }
    },
    modus () {
      return this.$store.state.modus
    }
  },
  methods: {
    convertDate (datetime) {
      if (datetime) {
        return new moment(datetime).format('DD.MM.YYYY')
      } else {
        return
      }
    },
    convertDateTime (datetime) {
      if (datetime) {
        return new moment(datetime).format('DD.MM.YYYY HH:mm')
      } else {
        return
      }
    },
    convertSeconds (sec) {
      if (sec > 0) {
        let hours   = Math.floor(sec / 3600)
        let minutes = Math.floor((sec - (hours * 3600)) / 60)
        let seconds = sec - (hours * 3600) - (minutes * 60)
        if (hours   < 10) {hours   = `0${hours}`}
        if (minutes < 10) {minutes = `0${minutes}`}
        if (seconds < 10) {seconds = `0${seconds}`}
        return `${hours}:${minutes}:${seconds}`
      } else {
        return ""
      }
    },
  }
}
