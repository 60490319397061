<template>
  <div class="scan-order" align="center">
    <p>Auftrag scannen</p>
    <div class="field">
      <input v-focus class="input scan" v-debounce:500ms="checkOrder" v-model="scannedOrderId" placeholder="Auftrag scannen" autofocus/>
    </div>
    <p class="has-text-danger" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
//const experimentalOrderId = "100082001"

import Vue from 'vue'
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)
import client from '@/client'

export default {
  data () {
    return {
      error: null,
      scannedOrderId: null,
    }
  },
  computed: {
    operatingOrder: {
      get () {
        return this.$store.state.operatingOrder
      },
      set (val) {
        this.$store.commit('setOperatingOrder', val)
      }
    },
  },
  methods: {
    async checkOrder () {
      try {
        const token = localStorage['auth._token.jwt']
        const res = await client.get(`/orders?internal_identifier=${this.scannedOrderId}`, { headers: { "Authorization": `Bearer ${token}` }})
        // result is a collection so it has to be filtered again
        if (res) {
          const foundOrder = res.data.resources.find(order => order.internal_identifier === this.scannedOrderId)
          if (foundOrder) {
            this.operatingOrder = foundOrder
            this.error = null
            this.currentStep = 'ORDER'
          } else {
            this.setError()
          }
        } else {
          this.setError()
        }
      } catch (error) {
        console.log(error)
        this.setError()
      }
    },
    setError () {
      if (this.scannedOrderId) {
        this.error = `Auftrag ${this.scannedOrderId} nicht erkannt!`
      }
      this.scannedOrderId = ''
    }
  }
}
</script>

<style lang="css" scoped>
</style>
