<template>
  <div>
    {{ currentTask.name }} | {{ timer }}
  </div>
</template>

<script>

const unlockThreshold = 1111120
export default {
  props: {
    currentTask: Object,
    doneAmount: Number,
    secondsTillUnlockRefresh: Number
  },
  data () {
    return {
      timer: '00:00:00',
      hours: 0,
      minutes: 0,
      seconds: 0,
      secondsTillLocked: unlockThreshold,
      interval: null,
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.triggerTimerCycle()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    triggerTimerCycle () {
      if (this.timerRunning) {
        this.seconds = parseInt(this.seconds)
        this.minutes = parseInt(this.minutes)
        this.hours = parseInt(this.hours)

        this.seconds += 1

        if (this.secondsTillLocked > 0) {
          this.secondsTillLocked -= 1
        } else {
          this.locked = true
        }

        if (this.seconds === 60) {
          this.minutes +=  1
          this.seconds = 0
        }
        if (this.minutes === 60) {
          this.hours += 1
          this.minutes = 0
          this.seconds = 0
        }

        if (this.seconds < 10 || this.seconds == 0) {
          this.seconds = '0' + this.seconds;
        }
        if (this.minutes < 10 || this.minutes == 0) {
          this.minutes = '0' + this.minutes;
        }
        if (this.hours < 10 || this.hours == 0) {
          this.hours = '0' + this.hours;
        }

        this.timer = this.hours + ':' + this.minutes + ':' + this.seconds
      }
    },
  },
  watch: {
    currentTask () {
      this.seconds = 0
      this.minutes = 0
      this.hours = 0
      this.secondsTillLocked = unlockThreshold
      this.timer = '00:00:00'
    },
    doneAmount () {
      this.secondsTillLocked = unlockThreshold
    },
    secondsTillUnlockRefresh () {
      this.secondsTillLocked = unlockThreshold
    },
    locked () {
      this.secondsTillLocked = unlockThreshold
    }
  }
}
</script>

<style lang="css" scoped>
</style>
