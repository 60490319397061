import client from '@/client'
import trackTime from '@/mixins/trackTime'
export default {
  mixins: [ trackTime ],
  computed: {
    operatingOrder: {
      get () {
        return this.$store.state.operatingOrder
      },
      set (val) {
        this.$store.commit('setOperatingOrder', val)
      }
    },
    operatingUser () {
      return this.$store.state.operatingUser
    },
  },
  methods: {
    async checkActiveOrder (orderId, comesFromPause = false) {
      if (orderId) {
        try {
          const token = localStorage['auth._token.jwt']
          const res = await client.get(`/orders/${orderId}`, { headers: { "Authorization": `Bearer ${token}` }})
          if (res) {
            const foundOrder = res.data.resource
            this.operatingOrder = foundOrder
            this.error = null
            this.currentStep = 'ORDER'
            // when comes from pause, we need to re track the order
            // this is not needed anymore in v2
            // if (comesFromPause && this.operatingUser.active_child_order_id) {
            //   this.trackTime(this.operatingUser.active_child_order_id, 'work')
            // }
          } else {
            if (comesFromPause) {
              this.currentStep = 'SCAN_ORDER'
            } else {
              this.currentStep = 'CONTROLS'
            }
          }
        } catch (error) {
          console.log(error)
          this.currentStep = 'CONTROLS'
        }
      } else {
        this.currentStep = 'SCAN_ORDER'
      }
    },
  }
}