<template>
  <div>
    <div class="button is-small pause" @click="commentModalOpen = true">Kommentar anlegen</div>

    <CommentModal
      v-if="commentModalOpen"
      :has-ctas="false"
      @close="commentModalOpen = false"
    >
      <Comments />
    </CommentModal>
  </div>
</template>

<script>
import CommentModal from '@/components/Modal'
import Comments from '@/components/Comments'
export default {
  components: { CommentModal, Comments },
  props: {},
  data () {
    return {
      commentModalOpen: false
    }
  },
  computed: {},
  methods: {
    close () {
      this.commentModalOpen = false
    }
  }
}
</script>

<style lang="sass" scoped>
.button.pause
  height: 35px
</style>
