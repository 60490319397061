import client from '@/client'
export default {
  methods: {
    async checkAuthorized () {
      const currentUser = this.$store.state.currentUser
      const token = localStorage['auth._token.jwt']
      if (localStorage['auth._token.jwt'] && !currentUser) {
        try {
          const res = await client.get('/users/me', { headers: { "Authorization": `Bearer ${token}` }})
          // Inject in Vue, context and store.
          if (res.data) {
            this.$store.commit('setCurrentUserAndOrganization', res.data.resource)
            this.$store.commit('setAuthorized', true)
            return
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$store.commit('setAuthorized', false)
          }
        }
      } else {
        this.$store.commit('setAuthorized', false)
      }
    },
  }
}
