<template>
  <div class="time-tracking">
    <div class="logged-in-as">Angemeldet als: {{ operatingUser.name }}<span v-if="modus === 'pause'"> | Kommen & Gehen</span></div>
    <div class="clock"><Clock /></div>
    <Controls v-if="currentStep === 'CONTROLS'" />
    <ScanOrder v-else-if="currentStep === 'SCAN_ORDER'" />
    <Order v-else-if="currentStep === 'ORDER' && operatingOrder" />
  </div>
</template>

<script>
import Controls from '@/components/time-tracking/Controls'
import ScanOrder from '@/components/time-tracking/ScanOrder'
import Order from '@/components/time-tracking/Order'
import Clock from '@/components/Clock'
export default {
  components: { Controls, ScanOrder, Order, Clock },
  computed: {
    operatingOrder () {
      return this.$store.state.operatingOrder
    },
    operatingUser () {
      return this.$store.state.operatingUser
    }
  }
}
</script>

<style lang="sass" scoped>
.time-tracking
  .logged-in-as, .clock
    font-size: 14px
    position: absolute
    top: 10px
    left: 10px
  .clock
    left: auto
    right: 10px
</style>
