<template>
    <EnterAmountModal
      @close="emitClose"
      @confirm="emitUpdateMyAmount"
      cta="Bestätigen"
      cta-type="is-success"
      :cta-disabled="myAmount === null"
    >
        <div class="columns">
            <div class="column">
                <div class="label">Soll</div>
                <input class="input is-borderless" type="text" readonly v-model="currentTask.target_amount" />
            </div>
            <div :class="['column', {'has-feedback-danger' : (currentTask.done_amount + parseInt(myAmount)) > currentTask.target_amount}]">
                <div class="label">Meine Menge</div>
                <input class="input" type="text" readonly v-model="myAmount" />
            </div>
            <div :class="['column has-feedback-warning', {'is-completed' : (currentTask.done_amount + parseInt(myAmount)) >= currentTask.target_amount}]">
                <div class="label">Ist Menge</div>
                <input class="input is-borderless" type="text" readonly v-model="doneAmount" />
            </div>
        </div>
        <div class="hint" v-if="(currentTask.done_amount + parseInt(myAmount) > currentTask.target_amount)">
            Hinweis: Die erforderliche Soll-Menge wurde überschritten!
        </div>
        <div class="hint" v-else-if="parseInt(myAmount) === 0">
            Bitte geben Sie die Menge ein:
        </div>
        <div class="hint" v-else>
            Bitte geben Sie die Menge ein:
        </div>
        <div class="numpad">
            <div class="pad-button num1" @click="concatNumber('7')">7</div>
            <div class="pad-button num2" @click="concatNumber('8')">8</div>
            <div class="pad-button num3" @click="concatNumber('9')">9</div>
            <div class="pad-button num4" @click="concatNumber('4')">4</div>
            <div class="pad-button num5" @click="concatNumber('5')">5</div>
            <div class="pad-button num6" @click="concatNumber('6')">6</div>
            <div class="pad-button num7" @click="concatNumber('1')">1</div>
            <div class="pad-button num8" @click="concatNumber('2')">2</div>
            <div class="pad-button num9" @click="concatNumber('3')">3</div>
            <div class="pad-button num10" @click="concatNumber('0')">0</div>
            <div class="pad-button num11" @click="resetNumpad()">C</div>
        </div>
    </EnterAmountModal>
</template>

<script>
import EnterAmountModal from '@/components/Modal'
export default {
    components: { EnterAmountModal },
    props: {
        currentTask: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            myAmount: null
        }
    },
    computed: {
        doneAmount () {
            if (this.myAmount) {
                return this.currentTask.done_amount + parseInt(this.myAmount)
            } else {
                return this.currentTask.done_amount
            }
            
        }
    },
    mounted () {},
    methods: {
        emitClose () {
            this.$emit('close')
        },
        emitUpdateMyAmount () {
            this.$emit('updateMyAmount', this.myAmount)
        },
        concatNumber (number) {
            if (this.myAmount === '0' || this.myAmount === null) {
                this.myAmount = number
            } else {
                this.myAmount += number
            }
        },
        resetNumpad () {
            this.myAmount = null
        }
    },
    watch: {},
}
</script>

<style lang="sass" scoped>
.numpad
    width: 65%
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: repeat(4, 1fr)
    grid-column-gap: 3px
    grid-row-gap: 3px
    grid-auto-rows: 1fr
.pad-button
    padding: 20px
    font-size: 25px
    color: white
    background: grey
    &:active
        background: green
.num1
    grid-area: 1 / 1 / 2 / 2
.num2
    grid-area: 1 / 2 / 2 / 3
.num3
    grid-area: 1 / 3 / 2 / 4
.num4
    grid-area: 2 / 1 / 3 / 2
.num5
    grid-area: 2 / 2 / 3 / 3
.num6
    grid-area: 2 / 3 / 3 / 4
.num7
    grid-area: 3 / 1 / 4 / 2
.num8
    grid-area: 3 / 2 / 4 / 3
.num9
    grid-area: 3 / 3 / 4 / 4
.num10
    grid-area: 4 / 1 / 5 / 3
.num11
    grid-area: 4 / 3 / 5 / 4

.input
    text-align: center
    font-size: 40px
    border: 3px solid grey
    &.is-borderless
        border: 0
        opacity: 0.9
.column
    &.has-feedback-danger
        input
            background: red
            color: white
    &.has-feedback-warning
        input
            background: orange
    &.is-completed
        input
            background: lightgreen

.hint
    text-align: left
    font-size: 20px
    margin-bottom: 10px
    background-color: red
    color: white
    padding: 5px
</style>