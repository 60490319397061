// Axios Config

var axios = require('axios')
// TODO: Outsource in dotenv => https://www.npmjs.com/package/dotenv
// const domain = process.env.VUE_APP_API_URL
// const domain = 'http://localhost:3000/v1'
const domain = 'https://api.pocket-rocket.io/v1'
// const domain = 'https://api-test.pocket-rocket.io/v1'

var axiosInstance = axios.create({
  baseURL: `${domain}`
});

module.exports = axiosInstance
