<template>
  <div id="app" class="container">
    <LockedModal
      v-if="locked"
      :can-close="false"
      :has-ctas="false"
    >
      <ScanUser
        :locked-screen="true"
        @setStep="unlockAndGoToStep"
      />
    </LockedModal>
    <div v-else>
      <div v-if="authorized === true">
        <div v-if="loading">Laden …</div>
        <div v-else>
          <ScanUser
            v-if="!operatingUser"
            @setStep="unlockAndGoToStep"
          />
          <TimeTracking v-else />
        </div>
      </div>
      <Login v-else-if="authorized === false" />
    </div>
  </div>
</template>

<script>

import ScanUser from '@/components/ScanUser'
import LockedModal from  '@/components/Modal'
import TimeTracking from '@/components/TimeTracking'
import Login from '@/components/Login'
import me from '@/mixins/me'
import checkActiveOrderMixin from '@/mixins/checkActiveOrder'
import userTimeRecording from '@/mixins/userTimeRecording'

export default {
  components: { ScanUser, LockedModal, TimeTracking, Login },
  mixins: [ me, checkActiveOrderMixin, userTimeRecording ],
  data () {
    return {
    }
  },
  computed: {
    operatingUser () {
      return this.$store.state.operatingUser
    },
    authorized () {
      return this.$store.state.authorized
    },
    // might be needed someday
    loading () {
      return false
    },
    userActiveParentOrderId() {
      if (this.$store.state.userTimeRecordingStatus) {
        return this.$store.state.userTimeRecordingStatus.active_parent_order
      }
      return null
    },
    userTimeRecordingStatus () {
      if (this.$store.state.userTimeRecordingStatus) {
        return this.$store.state.userTimeRecordingStatus.status
      }
      return null
    },
  },
  mounted () {
    // modus which can have implications on Frontend processes
    let modus = new URL(location.href).searchParams.get('modus')
    if (modus) {
      this.$store.commit('setModus', modus)
    }

    this.checkAuthorized()

    window.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    }, false)
  },
  methods: {
    async unlockAndGoToStep () {
      this.locked = false
      if (this.operatingUser) {
        await this.getUserTimeRecordingStatus(this.operatingUser.id)

        if (this.userActiveParentOrderId) {
          this.checkActiveOrder(this.userActiveParentOrderId)
        } else {
          this.currentStep = 'CONTROLS'
        }
        
        // end the pause here after unlock if needed
        if (this.userTimeRecordingStatus === 'pause') {
          this.trackTime(null, 'pause_stop')
        }
      } else {
        console.error('No operating user')
        this.currentStep = 'CONTROLS'
      }
    },
  },
  watch: {
    operatingUser () {
      if (this.operatingUser) {
        this.getUserTimeRecordingStatus(this.operatingUser.id)
      }
    }
  }
}
</script>

<style lang="sass">
#app
  padding: 50px
</style>
