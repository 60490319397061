<template>
  <div class="order">
    <EnterAmount
      v-if="enterAmountModalOpen"
      :current-task="currentTask"
      @close="closeEnterAmountModal"
      @updateMyAmount="updateMyAmount"
    />
    <div class="columns">
      <div class="column">
        <img src="@/assets/images/logo.png" class="logo" />
      </div>
      <div class="column is-narrow">
        <PauseButton
          :current-task="currentTask"
          :loading="loading"
        />
      </div>
      <div class="column is-narrow">
        <LockButton
          :loading="loading"
        />
      </div>
      <div class="column is-narrow">
        <AdditionalOrderButton
          :loading="loading"
        />
      </div>
      <div class="column is-narrow">
        <EndButton :current-task="currentTask" :time-loading="loading" />
      </div>
      <div class="column is-narrow">
        <SummaryButton />
      </div>
      <div class="column is-narrow">
        <CommentButton :operatingOrder="operatingOrder" />
      </div>
    </div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="is-size-5">Fertigungsauftrag <u>{{ operatingOrder.internal_identifier }}</u>: {{ operatingOrder.name }}</div>
        <Timer
          v-if="currentTask"
          :current-task="currentTask"
          :done-amount="parseInt(currentTask.done_amount)"
          :seconds-till-unlock-refresh="secondsTillUnlockRefresh"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-5">
        <div :class="[`task-box box-${index}`, { 'is-active' : task === currentTask }]" v-for="(task, index) in filteredOrderTasks" :key="index" @click="setTaskAndPosition(task, index)">
          {{ task.name }}
          <font-awesome-icon icon="lock" v-if="actionsLocked && task.id !== currentTask.id" />
        </div>
      </div>
      <div class="column is-7" v-if="!enterAmountModalOpen">
        <div v-if="currentTask && !timerRunning" :style="{ marginTop: `${detailedPosition}px` }" class="task-box is-detailed is-two-columned box-ctas">
          <div class="task-column has-background-success has-text-white" @click="startTask()">
            <div class="lds-dual-ring small" v-if="loading"></div>
            <span v-else>Start</span>
          </div>
          <div class="task-column has-background-danger has-text-white" @click="handleCancel()">
            <span v-if="loading" class="disabled-cancel">Abbrechen</span>
            <span v-else>Abbrechen</span>
          </div>
        </div>
        <div class="task-box is-detailed box-0" v-else-if="currentTask && timerRunning && currentTask.service.is_not_productive" :style="{ marginTop: `${detailedPosition}px` }">
          <div class="task-column">
            <div class="label">Soll</div>
            0
          </div>
          <div class="task-column">
            <div class="label">Meine Menge</div>
            0
          </div>
          <div class="task-column">
            <div class="label">Ist Menge</div>
            0
          </div>
          <div class="task-column"></div>
          <div class="delete" @click="resetCurrentTask()" />
        </div>
        <div class="task-box is-detailed box-0" v-else-if="currentTask && timerRunning" :style="{ marginTop: `${detailedPosition}px` }">
          <div class="task-column">
            <div class="label">Soll</div>
            {{ currentTask.target_amount }}
          </div>
          <div @click="openEnterAmountModal" :class="['task-column', {'has-feedback-warning' : (currentTask.done_amount + parseInt(myAmount)) > currentTask.target_amount}]">
            <div class="label">Meine Menge</div>
            {{ parseInt(myAmount) }}
          </div>
          <div :class="['task-column has-feedback-warning', {'is-completed' : (currentTask.done_amount + parseInt(myAmount)) >= currentTask.target_amount}]">
            <div class="label">Ist Menge</div>
            {{ currentTask.done_amount + parseInt(myAmount) }}
          </div>
          <div class="task-column">
            <span class="is-size-6" v-if="currentTask.user">{{ currentTask.user.name }}</span>
            <span class="is-size-7">Letzte Änderung:</span>
            <span class="is-size-6">{{ convertDate(currentTask.updated_at) }}</span>
          </div>
          <div class="delete" @click="resetCurrentTask()" />
        </div>
        <div v-else>
          <div v-if="showSuccessMessage" class="success-message">Ihre Menge wurde erfolgreich gespeichert!</div>
          Wählen Sie eine <span v-if="showSuccessMessage">neue </span>Aufgabe
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import client from '@/client'

import Timer from '@/components/Timer'
import PauseButton from '@/components/PauseButton'
import EndButton from '@/components/EndButton'
import LockButton from '@/components/LockButton'
import SummaryButton from '@/components/SummaryButton'
import CommentButton from '@/components/CommentButton'
import EnterAmount from '@/components/EnterAmount'
import AdditionalOrderButton from '@/components/AdditionalOrderButton'
export default {
  components: { Timer, PauseButton, EndButton, LockButton, EnterAmount, AdditionalOrderButton, SummaryButton, CommentButton },
  data () {
    return {
      detailedPosition: 0,
      currentTask: null,
      orderTasks: [],
      myAmount: 0,
      secondsTillUnlockRefresh: 0,
      enterAmountModalOpen: false,
      showSuccessMessage: false,
      loading: false
    }
  },
  computed: {
    actionsLocked () {
      return this.currentTask && (this.currentTask.is === 0 || !this.currentTask.is)
    },
    operatingOrder () {
      return this.$store.state.operatingOrder
    },
    operatingUser () {
      return this.$store.state.operatingUser
    },
    // HARDCODED exception for services that must not be shown
    // TODO: Make configureable (e.g. via globals)
    filteredOrderTasks () {
      if (this.operatingUser.role === 'user') {
        return this.orderTasks.filter(task => task.service && task.service.internal_identifier !== '1015' && task.service.internal_identifier !== '1014')
      }
      return this.orderTasks
    }
  },
  mounted () {
    this.retrieveChildOrders()
  },
  beforeDestroy () {
    this.timerRunning = false
  },
  methods: {
    openEnterAmountModal () {
      this.timerRunning = false
      this.enterAmountModalOpen = true
    },
    closeEnterAmountModal () {
      this.timerRunning = true
      this.enterAmountModalOpen = false
    },
    resetTimer () {
      this.secondsTillUnlockRefresh += 1
    },
    selectInput (e) {
      e.target.select()
    },
    setTaskAndPosition (task, index) {
      this.showSuccessMessage = false
      if (!this.actionsLocked) {
        this.currentTask = task
        this.detailedPosition = index * 57
      }
    },
    handleCancel () {
      if (!this.loading) {
        this.currentTask = null
      }
    },
    async startTask () {
      if (!this.loading) {
        this.loading = true
        await this.trackTime(this.currentTask.id, 'work')
        this.timerRunning = true
        this.loading = false
      }
    },
    async retrieveChildOrders () {
      try {
        const token = localStorage['auth._token.jwt']
        const res = await client.get(`/orders/${this.operatingOrder.id}/childs?sort_by=service.internal_identifier&sort_oder=ASC`, { headers: { "Authorization": `Bearer ${token}` }})
        if (res) {
          this.orderTasks = res.data.resources
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateMyAmount (amount) {
      this.enterAmountModalOpen = false
      this.myAmount = amount
      if (this.currentTask) {
        const myAmount = parseInt(this.myAmount)
        const doneAmount = parseInt(this.currentTask.done_amount) + myAmount
        try {
          const token = localStorage['auth._token.jwt']
          let status = 'in_progress'
          if (doneAmount >= this.currentTask.target_amount) {
            status = 'completed'
          }

          const data = {
            done_amount: doneAmount,
            user_id: this.operatingUser.id,
            status: status
          }
          const res = await client.put(`/orders/${this.currentTask.id}`, data, { headers: { "Authorization": `Bearer ${token}` }})
          if (res) {
            console.log("done amount updated")
            this.currentTask.done_amount = doneAmount
            this.currentTask = null
            this.timerRunning = false
            this.myAmount = 0
            this.showSuccessMessage = true
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    resetCurrentTask () {
      this.currentTask = null
      this.timerRunning = false
    },
  },
  watch: {
    currentTask () {
      this.myAmount = 0
    },
    myAmount () {
      if (parseInt(this.myAmount) < 0) {
        this.myAmount = 0
      }
      if (!parseInt(this.myAmount)) {
        this.myAmount = 0
      }
    },
    orderTasks () {
      if (this.operatingUser.last_item_in_time_chain) {
        const foundActiveTask = this.orderTasks.find(task => task.id === this.operatingUser.last_item_in_time_chain.order_id)
        const index = this.orderTasks.indexOf(foundActiveTask)
        if (foundActiveTask) {
          this.timerRunning = true
          this.setTaskAndPosition (foundActiveTask, index)
        }
      }
    },
    operatingOrder () {
      this.retrieveChildOrders()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/utilities/initial-variables"
.order
  .task-box
    position: relative
    text-align: center
    padding: 15px 0
    border-left: 1px solid grey
    border-bottom: 1px solid grey
    border-right: 1px solid grey
    .delete
      position: absolute
      right: -40px
      top: 23px
    .svg-inline--fa
      position: absolute
      right: 10px
      top: 23px
    &.is-active
      background: grey
      color: white
    &.box-0
      border-top: 1px solid grey
    &.is-detailed
      text-align: center
      padding: 0
      display: grid
      grid-template-columns: 25% 25% 25% 25%
      border-right: none
      &.is-two-columned
        grid-template-columns: 50% 50%
      &.box-ctas
        border: 0
      input
        text-align: center
        border: 0
        font-size: 44px
        width: 80%
        &:focus
          outline: 0
      .task-column
        position: relative
        font-size: 40px
        display: grid
        place-items: center
        border-right: 1px solid grey
        &.has-feedback-warning
          background: orange
          input
            background: orange
        &.is-completed
          background: lightgreen
        .label
          font-size: 10px
          position: absolute
          top: 1px
          left: 1px
      &.box-ctas .task-column
        border: 0
        font-size: 24px
        padding: 10px 0
.logo
  width: 30%
.success-message
  font-size: 30px
.disabled-cancel
  cursor: not-allowed
  opacity: 0.5
</style>
