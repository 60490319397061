<template>
  <div class="summary">
    <div v-if="loading">Kommentare werden geladen...</div>
    <div v-else>
      <div align="center">Drücken um Kommentar zu erfassen</div>
      <div class="comment-text-button" v-for="comment in commentText" :key="comment.id">
        <div
          @click="
            createComment(comment.content);
            fetchComments();
          "
        >
          {{ comment.content }}
        </div>
      </div>
      <div class="gap" />
      <div align="left" class="comment-headline" v-if="comments.length > 0">
        Vergangene Kommentare:
      </div>
      <div class="gap" />
      <div v-for="comment in comments" :key="comment.id" class="comment-container">
        <button
          class="comment-button"
          :class="{ 'current-user': comment.user_id === operatingUser.id }"
        >
          <div align="left" v-html="comment.content"></div>
          <div align="right">
            {{ convertDateTime(comment.created_at) }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/client";
export default {
  components: {},
  props: {},
  data() {
    return {
      comments: [],
      commentText: [
        {
          content: "Nacharbeit Vormaterial",
        },
        {
          content: "Abklärung Fertigung",
        },
        {
          content: "Freigabe",
        },
        {
          content: "Abklärung Techn. Büro",
        },
        {
          content: "Material nicht vorhanden",
        },
      ],
      loading: false,
    };
  },
  computed: {
    operatingOrder() {
      return this.$store.state.operatingOrder;
    },
    operatingUser() {
      return this.$store.state.operatingUser;
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchComments();
    this.loading = false;
  },
  methods: {
    async getOperatingOrder() {
      try {
        const token = localStorage["auth._token.jwt"];
        const res = await client.get(`/orders/${this.operatingOrder.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res) {
          this.$store.commit("setOperatingOrder", res.data.resource);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchComments() {
      try {
        const token = localStorage["auth._token.jwt"];
        const res = await client.get(
          `/comments?model_id=${this.operatingOrder.id}&table_name=Order&per_page=1000&page=1?sort_by=created_at&sort_order=DESC`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res) {
          this.comments = res.data.resources;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createComment(commentText) {
      try {
        const token = localStorage["auth._token.jwt"];
        const data = {
          model_id: this.operatingOrder.id,
          table_name: "Order",
          content: `${this.operatingUser.name} schreibt: ${commentText}`,
        };
        const res = await client.post("/comments", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res) {
          this.fetchComments();
          this.getOperatingOrder();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.comment-text-button
  border: 1px solid
  border-radius: 5px
  margin: 20px 0
  padding: 10px 0
.comment-headline
  font-size: 1rem
.comments-table
  font-size: 2rem
.comments
  font-size: 2rem !important
  padding: 20px 0

.comment-container
  margin-bottom: 10px

.current-user
  background: #D3D3D3

.comment-button
  border: 1px solid #000
  font-size: 1.1rem
  display: flex
  justify-content: space-between
  align-items: center
  padding: 10px
  width: 100%
</style>
