import { render, staticRenderFns } from "./ScanOrder.vue?vue&type=template&id=687f0606&scoped=true"
import script from "./ScanOrder.vue?vue&type=script&lang=js"
export * from "./ScanOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687f0606",
  null
  
)

export default component.exports