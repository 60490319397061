import client from '@/client'

export default {
  methods: {
    async getUserTimeRecordingStatus(userId) {
      try {
        const token = localStorage['auth._token.jwt']
        const res = await client.get(`/employee_time_recordings/status?user_id=${userId}`, { headers: { "Authorization": `Bearer ${token}` }})
        this.$store.commit('setUserTimeRecordingStatus', res.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
