import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    authorized: null,
    encodedToken: null,
    // admin user to login to the dashboard:
    currentUser: null,
    // operating user doing the work:
    operatingUser: null,
    operatingOrder: null,
    currentStep: 'CONTROLS',
    timerRunning: false,
    locked: false,
    clock: null,
    modus: null,
    globals: null,
    userTimeRecordingStatus: null
  },
  mutations: {
    setClock (state, val) {
      state.clock = val
    },
    setAuthorized(state, val) {
      state.authorized = val
    },
    setCurrentUserAndOrganization(state, val) {
      state.currentUser = val
    },
    setEncodedToken(state, val) {
      state.encodedToken = val
    },
    setOperatingUser (state, val) {
      state.operatingUser = val
    },
    setOperatingOrder (state, val) {
      state.operatingOrder = val
    },
    setCurrentSet (state, val) {
      state.currentStep = val
    },
    setTimerRunning (state, val) {
      state.timerRunning = val
    },
    setLocked (state, val) {
      state.locked = val
    },
    setModus (state, val) {
      state.modus = val
    },
    setUserTimeRecordingStatus (state, val) {
      state.userTimeRecordingStatus = val
    }
  },
})

export default store
