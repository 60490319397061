<template>
  <div class="summary">
    <div align="left">Zusammenfassung</div>
    <div class="gap" />
    <table width="100%">
      <thead>
        <th></th>
        <th>Auftrag No.</th>
        <th>Arbeit</th>
        <th>Wann</th>
        <th>Zeit</th>
      </thead>
      <tbody>
        <tr v-for="trackedTime in trackedTimes" :key="trackedTime.id">
          <td>{{ parseKind(trackedTime.kind) }}</td>
          <td></td>
          <td><span v-if="trackedTime.time_range.orders.length > 0">{{ trackedTime.time_range.orders[0].name }}</span></td>
          <td>{{ convertDateTime(trackedTime.time_range.begin) }}</td>
          <td>
            {{ convertSeconds(trackedTime.time_range.duration_seconds) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import client from '@/client'
import moment from 'moment'
export default {
  components: {},
  props: {},
  data () {
    return {
      trackedTimes: []
    }
  },
  computed: {
    operatingUser () {
      return this.$store?.state?.operatingUser
    }
  },
  mounted () {
    this.fetchTrackedTimesForTimeChain()
  },
  methods: {
    async fetchTrackedTimesForTimeChain () {
      try {
        const today = moment().format('YYYY-MM-DD');
        const token = localStorage['auth._token.jwt']
        const res = await client.get(`employee_time_recordings?employee_time_days.day=${today}&user.id-in=${this.operatingUser?.id}`, { headers: { "Authorization": `Bearer ${token}` }})

        if (res) {
          this.trackedTimes = res['data']['resources']
        }
      } catch (error) {
        console.log(error)
      }
    },
    parseKind (kind) {
      if (kind === 'working_time') {
        return "Auszeit"
      }
      if (kind === 'project_time') {
        return "Auftrag Arbeit"
      }
      if (kind === 'pause_start') {
        return "Pause Beginn"
      }
      if (kind === 'pause_end') {
        return "Pause Ende"
      }
      if (kind === 'work') {
        return "Arbeit"
      }
    },
    calculateSecondsTillNow (timestamp) {
      const secondsTillNow = (moment().unix() - moment(timestamp).unix())
      return this.convertSeconds(secondsTillNow)
    }
  },
  watch: {},
}
</script>

<style lang="sass" scoped>
.summary
  table
    font-size: 13px
    th, td
      padding: 3px 10px
</style>
