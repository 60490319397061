<template>
  <div class="controls">
    <div v-if="operatingUser && modus === 'pause'" align="center" class="status">
      <img src="@/assets/images/finish.gif" /><br/><br/>Sie wurden erfolgreich <span v-if="this.operatingUser.has_running_timer">abgemeldet</span><span v-else>registriert</span>.<br/>Diese Seite wird nach wenigen Sekunden geschlossen.<br/><a @click="operatingUser = null">zurück</a>
    </div>
    <div v-else-if="userTimeRecordingStatus">
      <WarningModal
        v-if="warningModalOpen"
        @close="warningModalOpen = false"
        @confirm="leave()"
        :loading="loading"
      >
        Möchten Sie sich wirklich abmelden?
      </WarningModal>

      <div class="buttons is-centered">
        <button class="button is-primary" @click="workContinue()" v-if="userTimeRecordingStatus === 'working'">Arbeit fortsetzen</button>
        <button class="button is-primary" @click="come()" v-else-if="userTimeRecordingStatus === 'not_working'">Kommen</button>
        <button class="button is-light" @click="pauseStop()" v-if="userTimeRecordingStatus === 'pause'">Pause Ende</button>
        <button class="button is-light" @click="pauseStart()" v-else :disabled="userTimeRecordingStatus === 'not_working'">Pause Beginn</button>
        <!-- <button class="button is-light">Pause Ende</button> -->
        <button class="button is-light" @click="warningModalOpen = true" :disabled="userTimeRecordingStatus === 'not_working'">Gehen</button>
      </div>
    </div>
    <div v-else>
      Einen Moment bitte…
    </div>
  </div>
</template>

<script>
import WarningModal from '@/components/Modal'
import checkActiveOrderMixin from '@/mixins/checkActiveOrder'
import userTimeRecording from '@/mixins/userTimeRecording'
export default {
  components: { WarningModal },
  mixins: [ checkActiveOrderMixin, userTimeRecording ], 
  data () {
    return {
      warningModalOpen: false,
      loading: false
    }
  },
  computed: {
    userTimeRecordingStatus () {
      if (this.$store.state.userTimeRecordingStatus) {
        return this.$store.state.userTimeRecordingStatus.status
      }
      return null
    },
    operatingUser: {
      get () {
        return this.$store.state.operatingUser
      },
      set (val) {
        this.$store.commit('setOperatingUser', val)
      }
    }
  },
  mounted () {
    this.getUserTimeRecordingStatus(this.operatingUser.id)

    if (this.modus === 'pause') {

      if (this.operatingUser.has_running_timer) {
        this.trackTime(null, 'stop')
      } else {
        this.trackTime(null, 'start')
      }
      
      this.handlePauseMode()
    }
  },
  methods: {
    come () {
      this.trackTime(null, 'start')
      this.currentStep = 'SCAN_ORDER'
    },
    workContinue () {
      this.currentStep = 'SCAN_ORDER'
    },
    async leave () {
      this.loading = true
      await this.trackTime(null, 'stop')
      this.loading = false
      this.operatingOrder = null
      this.operatingUser = null
    },
    pauseStart () {
      this.trackTime(null, 'pause_start')
      this.locked = true
    },
    pauseStop () {
      this.trackTime(null, 'pause_stop')
      this.checkActiveOrder(this.operatingUser.active_parent_order_id, true)
    },
    handlePauseMode () {
      setTimeout(() => {
        this.operatingUser = null
      }, 5000)
    }
  }
}
</script>

<style lang="sass" scoped>
.status
  font-size: 30px
  img
    width: 90px
</style>
