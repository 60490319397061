<template>
  <div>
    <div class="button is-small lock" @click="handleClick()">Mitarbeiter wechseln</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  methods: {
    handleClick () {
      if (!this.loading) {
        this.locked = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.button.lock
  height: 35px
</style>
