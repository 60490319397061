<template>
  <div class="modal is-active">
    <div class="modal-background" @click="emitClose()"></div>
    <div class="modal-content">
      <div class="box" align="center">
        <div class="box-content is-size-3">
          <slot />
        </div>
        <div class="buttons" v-if="hasCtas">
          <div class="button is-half" @click="emitClose()">Abbrechen</div>
          <button :disabled="ctaDisabled" :class="['button is-half', ctaType]" @click="emitConfirm()">
            <div class="lds-dual-ring" v-if="loading"></div>
            <span v-else>{{ cta }}</span>
          </button>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="emitClose()" v-if="canClose"></button>
  </div>
</template>

<script>
export default {
  props: {
    hasCtas: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    cta: {
      type: String,
      default: 'Ja'
    },
    ctaType: {
      type: String,
      default: 'is-primary'
    },
    ctaDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClose () {
      if (this.canClose && !this.loading) {
        this.$emit('close')
      }
    },
    emitConfirm () {
      if (!this.ctaDisabled && !this.loading) {
        this.$emit('confirm')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.buttons
  display: grid
  grid-template-columns: 50% 50%
.box-content
  margin: 15px 0 15px 0
</style>
